import React, { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css"
import './App.css';
import GoogleMapReact from 'google-map-react';
import { findGamesGeoPoints, Row } from './database/db';
import { Marker } from './Marker';
import useSupercluster from 'use-supercluster';
import { ClusterMarker } from './ClusterMarker';
import ReactDatePicker from 'react-datepicker';

function App() {
  let [zoom, setZoom] = useState(6)
  let initStartTime = Math.max(new Date('2022/04/1').getTime(), Date.now())
  let [startTime, setStartTime] = useState(initStartTime)
  let [endTime, setEndTime] = useState(Math.min(startTime + (1000*60*60*24*7), new Date('2022/07/1').getTime()))
  const [bounds, setBounds] = useState<[number, number, number, number]>()
  let [selectedId, setSelectedId] = useState<string>()
  let {clusters, supercluster} = useSupercluster<Row, Row>({
    bounds,
    zoom,
    options: {},
    points: findGamesGeoPoints({
      startTime,
      endTime,
    })
  })

  return (
    <div className='App'>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <div>
          <h4 style={{display: 'inline'}}>Start</h4>
          <ReactDatePicker
            selected={new Date(startTime)}
            onChange={newStartTime => {
              if (newStartTime) {
                setStartTime(newStartTime.getTime())

                if (newStartTime.getTime() > endTime) {
                  setEndTime(newStartTime.getTime() + (1000 * 60 * 60 * 24 * 7))
                }
              }
            }}
          />
        </div>

        <div>
          <h4 style={{display: 'inline'}}>End</h4>
          <ReactDatePicker
            selected={new Date(endTime)}
            onChange={newEndTime => {
              if (newEndTime) {
                if (newEndTime.getTime() < startTime) {
                  setStartTime(newEndTime.getTime() - (1000 * 60 * 60 * 24 * 7))
                }

                newEndTime.setHours(23)
                newEndTime.setMinutes(59)
                setEndTime(newEndTime.getTime())
              }
            }}
          />
        </div>
      </div>
      <div style={{height: '95%'}}>
        <GoogleMapReact
          onChange={({zoom, bounds}) => {
            setZoom(zoom)
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat
            ])
          }}
          defaultCenter={{lat: 54, lng: -3}}
          defaultZoom={6}
          bootstrapURLKeys={{ key: 'AIzaSyAehVxAU84pmuxRHOc8esqc35ky1Ig-DLE' }}
        >
          {clusters.map(cluster => {
            if ((cluster.properties as any).cluster) {
              let rows = supercluster?.getLeaves(cluster.id as number).map(leaf => leaf.properties) || [] as Row[]
              return (
                <ClusterMarker
                  key={cluster.id}
                  selected={selectedId === cluster.id}
                  setSelected={(isSelected: boolean) => setSelectedId(isSelected ? cluster.id as string : undefined)}
                  zoom={zoom}
                  lat={cluster.geometry.coordinates[1]}
                  lng={cluster.geometry.coordinates[0]}
                  rows={rows}
                />
              )
            } else {
              return (
                <Marker
                  key={JSON.stringify(cluster.properties)}
                  setSelected={(isSelected: boolean) => setSelectedId(isSelected ? JSON.stringify(cluster.properties) : undefined)}
                  selected={selectedId === JSON.stringify(cluster.properties)}
                  lat={cluster.properties.stadium.geometry.location.lat}
                  lng={cluster.properties.stadium.geometry.location.lng}
                  row={cluster.properties}
                  zoom={zoom}
                />
              )
            }
          })}
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default App;

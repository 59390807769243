import { Club, Row } from "./database/db"

type Props = {
  lat: number,
  lng: number,
  row: Row,
  zoom: number,
  selected: boolean,
  setSelected: (isSelected: boolean) => unknown
}

export let colorsByLeague: Record<Club['league'], string> = {
  'Premier League': 'red',
  'Championship': 'green',
  'League One': 'blue',
  'League Two': 'orange',
}

export let abbreviationByLeague: Record<Club['league'], string> = {
  'Premier League': 'PL',
  'Championship': 'C',
  'League One': '1',
  'League Two': '2',
}

type TooltipProps = {
  size: number
  row: Row
}
function Tooltip(props: TooltipProps) {
  let kickoff = new Date(props.row.fixture.kickoff)
  let kickoffTime = `${kickoff.getUTCHours()}:${('0' + kickoff.getMinutes()).slice(-2)}`
  let time = `${kickoff.toLocaleDateString()} @ ${kickoffTime}`

  return (
    <span
      style={{
        position: 'absolute',
        display: 'block',
        bottom: `${props.size}px`,
        backgroundColor: 'lightgrey',
        borderRadius: '5px',
        fontSize: `16px`,
        width: '250px',
        zIndex: 1000,
      }}
    >
      {props.row.homeTeam.name} v {props.row.awayTeam.name} {time}
    </span>
  )
}

export function Marker(props: Props) {
  let size = props.zoom * 3

  return (
    <span
      onMouseEnter={() => props.setSelected(true)}
      onMouseLeave={() => props.setSelected(false)}
      onClick={() => props.setSelected(!props.selected)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size}px`,
        background: colorsByLeague[props.row.homeTeam.league],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: `-${size/2}px`,
        left: `-${size/2}px`,
      }}
    >
      {props.selected ? <Tooltip row={props.row} size={size} /> : null}
      <span style={{color: 'white'}}>{abbreviationByLeague[props.row.homeTeam.league]}</span>
    </span>
  )
}

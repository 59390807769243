import clubs from './clubs.json'
import stadiums from './stadiums.json'
import fixtures from './fixtures.json'
import Supercluster from 'supercluster'

export type Club = {
  name: string
  stadiumAddress: string
  stadiumName: string
  league: 'Premier League' | 'Championship' | 'League One' | 'League Two'
}
let normalizedClubs = clubs as Club[]

let database = fixtures.map(fixture => {
  return {
    fixture,
    homeTeam: orElseThrow(normalizedClubs.find(club => club.name === fixture.home)),
    awayTeam: orElseThrow(normalizedClubs.find(club => club.name === fixture.away)),
    stadium: orElseThrow(stadiums.find(stadium => stadium.clubName === fixture.home))
  }
})

export type Row = typeof database[0]

type Query = {
  clubs?: string[]
  startTime?: number
  endTime?: number
  leagues?: Club['league']
}

export function orElseThrow<T>(value: T|undefined): T {
  if (value === undefined) {
    throw new Error('Value should not be undefined')
  }

  return value
}

export function getQueryOptions() {
  return {
    clubs: clubs.map(club => club.name),
    leagues: [...new Set([...clubs.map(club => club.league)])],
    beginTime: fixtures.reduce((earliest, fixture) => Math.min(earliest, fixture.kickoff), Infinity),
    endTime: fixtures.reduce((earliest, fixture) => Math.max(earliest, fixture.kickoff), -Infinity)
  }
}

export function findGamesGeoPoints(query: Query): Supercluster.PointFeature<Row>[] {
  let games = findGames(query)

  return games.map(game => {
    return {
      properties: game,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [game.stadium.geometry.location.lng, game.stadium.geometry.location.lat]
      }
    }
  })
}

export function findGames(query: Query): Row[] {
  // console.time()
  let result = database.filter(row => {
    // always remove games in the past
    if (row.fixture.kickoff < Date.now()) {
      return false
    }

    // remove unwanted clubs
    if (query.clubs) {
      if (!query.clubs.includes(row.fixture.home) && !query.clubs.includes(row.fixture.away)) {
        return false
      }
    }

    // remove unwanted leagues
    if (query.leagues) {
      if (!query.leagues.includes(row.homeTeam.league)) {
        return false
      }
    }

    // remove out of range times
    if (query.startTime) {
      if (row.fixture.kickoff < query.startTime) {
        return false
      }
    }

    if (query.endTime) {
      if (row.fixture.kickoff > query.endTime) {
        return false
      }
    }

    return true
  })

  // console.log({query, result})
  // console.timeEnd()
  return result
}

import { orElseThrow, Row } from "./database/db"
import { abbreviationByLeague, colorsByLeague } from "./Marker"

type Props = {
  lat: number,
  lng: number,
  zoom: number,
  rows: Row[]
  selected: boolean
  setSelected: (isSelected: boolean) => unknown
}

function getColor(rows: Row[]) {
  let highestRankedLeage = orElseThrow(Object.keys(colorsByLeague).find(key => {
    return rows.some(row => row.homeTeam.league === key)
  })) as keyof typeof colorsByLeague

  return colorsByLeague[highestRankedLeage]
}

function getContent(rows: Row[]) {
  let highestRankedLeage = orElseThrow(Object.keys(colorsByLeague).find(key => {
    return rows.some(row => row.homeTeam.league === key)
  })) as keyof typeof colorsByLeague

  return abbreviationByLeague[highestRankedLeage]
}

type TooltipProps = {
  size: number
  rows: Row[]
}
function Tooltip(props: TooltipProps) {
  let sortedRows = [...props.rows].sort((a, b) => {
    return a.fixture.kickoff - b.fixture.kickoff
  })
  return (
    <span
      style={{
        position: 'absolute',
        display: 'block',
        bottom: `${props.size}px`,
        backgroundColor: 'lightgrey',
        borderRadius: '5px',
        fontSize: `16px`,
        width: '250px',
        zIndex: 1000,
      }}
    >
      <ul>
        {sortedRows.map(row => {
          let kickoff = new Date(row.fixture.kickoff)
          let kickoffTime = `${kickoff.getUTCHours()}:${('0' + kickoff.getMinutes()).slice(-2)}`
          let time = `${kickoff.toLocaleDateString()} @ ${kickoffTime}`
          let content = `${row.homeTeam.name} v ${row.awayTeam.name} ${time}`
          return <li key={JSON.stringify(row)}>{content}</li>
        })}
      </ul>
    </span>
  )
}

export function ClusterMarker(props: Props) {
  let size = props.zoom * 5
  let color = getColor(props.rows)

  return (
    <div
      onMouseEnter={() => props.setSelected(true)}
      onMouseLeave={() => props.setSelected(false)}
      onClick={() => props.setSelected(!props.selected)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size}px`,
        background: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: `-${size/2}px`,
        left: `-${size/2}px`,
      }}
    >
      {props.selected ? <Tooltip rows={props.rows} size={size} /> : null}
      <span
        style={{
          fontSize: `${size * .5}px`,
          color: 'white',
        }}
      >
        {getContent(props.rows)}
      </span>
    </div>
  )
}
